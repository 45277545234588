export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'User Menu',
            'Master': 'Master',
            'Vendor': 'Vendor',
            'Admin': 'Admin',
            'Dashboard': 'Dashboard',
            'About': 'About',
            'Reports': 'Reports',
            'Inbound': 'Inbound',
            'Setup': 'Setup',
            'Internal': 'Internal',
            'Outbound Admin': 'Outbound Admin',
            'Pick Priority': 'Pick Priority',
            'DASHBOARDS': 'Dashboards',
            'Outbound Dashboard': 'Outbound Dashboard',
            'Outbound': 'Outbound',
            'Manifest': 'Manifest',
            'CALENDAR': 'Calendar',
            'ECOMMERCE': 'E-Commerce',
            'Warehouse Movement': 'Warehouse Movement',
            'ACADEMY': 'Academy',
            'MAIL': {
                'TITLE': 'Mail',
                'BADGE': '25'
            },
            'MAIL_NGRX': {
                'TITLE': 'Mail Ngrx',
                'BADGE': '13'
            },
            'CHAT': 'Chat',
            'FILE_MANAGER': 'File Manager',
            'CONTACTS': 'Contacts',
            'TODO': 'To-Do',
            'SCRUMBOARD': 'Scrumboard'
        }
    }
};
